import React from 'react';
import classes from './pageHeader.css';
import { string } from 'prop-types';

function PageHeader(props) {
    let { text } = props;

    return <div className={classes.root}>{text}</div>;
}

PageHeader.propTypes = {
    text: string.isRequired
};

export default PageHeader;
