import React from 'react';
import { object, string } from 'prop-types';
import { RuleIcons } from 'src/components/TableRowActionIcons';
import RuleToggle from 'src/components/RuleToggle';

function friendlyPriority(priority) {
    return priority + 1;
}

export default [
    {
        label: '',
        sortable: false,
        value: 'toggle',
        component: RowRuleToggle
    },
    {
        label: 'Priority',
        sortable: false,
        searchable: false,
        value: 'index',
        format: friendlyPriority
    },
    {
        label: 'Description',
        sortable: false,
        searchable: false,
        value: 'description',
        class: 'alignLeft'
    },
    {
        label: 'Start Date',
        sortable: false,
        searchable: false,
        value: 'effectiveDateStart'
    },
    {
        label: 'End Date',
        sortable: false,
        searchable: false,
        value: 'effectiveDateEnd'
    },
    {
        label: '',
        searchable: false,
        class: 'iconTray',
        value: 'icons',
        sortable: false,
        component: RuleIcons
    }
];

function RowRuleToggle(props) {
    const {
        row: { id, enabled, effectiveDateStart, effectiveDateEnd }
    } = props;
    return (
        <RuleToggle
            dates={{ effectiveDateStart, effectiveDateEnd }}
            enabled={enabled}
            id={id}
        />
    );
}

RowRuleToggle.propTypes = {
    row: object,
    effectiveDateStart: string,
    effectiveDateEnd: string
};
