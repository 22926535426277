import React from 'react';
import { func, bool, object, string } from 'prop-types';
import classes from './splash.css';

import { Button } from 'fronds/Controllers';

function Splash(props) {
    const { isCompact, image, message, callToAction, action } = props;

    const splashClass = !isCompact ? classes.splash : classes.splish;

    return (
        <div className={splashClass}>
            {image}
            <span className={classes.text}>
                <h3 className={classes.message}>{message}</h3>
                {callToAction && action ? (
                    <Button onClick={action}>{callToAction}</Button>
                ) : null}
            </span>
        </div>
    );
}

Splash.propTypes = {
    isCompact: bool,
    image: object,
    message: string,
    callToAction: string,
    action: func
};

export default Splash;
