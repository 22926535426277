import React, { useState } from 'react';
import classes from './treeForm.css';
import { string, bool, func, array } from 'prop-types';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';

function FieldDropdownSelect(props) {
    const {
        fieldTypes,
        fieldName,
        currentType,
        onSelectionChange,
        isEditing
    } = props;

    const [selectedType, setSelectedType] = useState(
        fieldTypes.find(type => type.value === currentType)
    );

    return isEditing ? (
        <span className={classes.readOnly}>{selectedType.label}</span>
    ) : (
        <DropdownSelectWrapper
            selectedValues={selectedType}
            listItems={fieldTypes}
            onSelectionChange={selection => {
                setSelectedType(
                    fieldTypes.find(type => type.value === selection.value)
                );
                onSelectionChange(selection, fieldName);
            }}
        />
    );
}

FieldDropdownSelect.propTypes = {
    fieldTypes: array,
    fieldName: string,
    currentType: string,
    onSelectionChange: func,
    isEditing: bool
};

export default FieldDropdownSelect;
