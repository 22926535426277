import React from 'react';
import { string, bool, func, number, oneOfType } from 'prop-types';
import useCurrentRule from './useCurrentRule';
import treeFormDescriptions from 'src/constants/treeFormDescriptions';

import { Toggle, FormField } from 'fronds/Controllers';

function NonAggregateFormFields(props) {
    const {
        payoutCanBeAddedToOtherRules = false,
        onFormChange,
        priorityIndex,
        isEditing,
        setCurrentRule
    } = props;

    // Add all properties to the current rule and
    // deletes them when unmounted
    useCurrentRule(setCurrentRule, {
        payoutCanBeAddedToOtherRules
    });

    return (
        <>
            <FormField
                label="Payout can be added to other rules:"
                description={treeFormDescriptions.payoutCanBeAddedToOtherRules}
            >
                <Toggle
                    defaultChecked={payoutCanBeAddedToOtherRules}
                    name="payoutCanBeAddedToOtherRules"
                    onChange={val => onFormChange(val, 'checked')}
                />
            </FormField>
            {isEditing ? (
                <FormField
                    label={'Priority Index:'}
                    description={treeFormDescriptions.priorityIndex}
                >
                    <p> {priorityIndex} </p>
                </FormField>
            ) : null}
        </>
    );
}

NonAggregateFormFields.propTypes = {
    payoutCanBeAddedToOtherRules: bool,
    onFormChange: func,
    priorityIndex: oneOfType([number, string]),
    isEditing: bool,
    setCurrentRule: func
};

export default NonAggregateFormFields;
