import { useEffect } from 'react';

function useCurrentRule(setCurrentRule, props) {
    useEffect(() => {
        // Set default values for form
        setCurrentRule(rule => ({
            ...rule,
            ...props
        }));
        return () => {
            setCurrentRule(rule => {
                Object.keys(props).forEach(field => {
                    delete rule[field];
                });
                return rule;
            });
        };
    }, []);
}

export default useCurrentRule;
