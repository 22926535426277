import React from 'react';
import DollarSign from 'feather/dollar-sign';
import Award from 'feather/award';
import Star from 'feather/star';
import Flag from 'feather/flag';

// commissionSearchType and overrideSearchType endpoint takes 0, 1, 2, and 3.
/*
    Filter statuses are the numbers passed to the endpoint - to understand these, here's an example for `commissionSearchType`:

    `all` would get all invoices regardless of commission status
    'has' would get all invoices that have a commission
    'doesNotHave' would get all invoices that do not have a commission
    'pending' would get all invoices that have a pending commission

*/
// isFullyPaid endpoint takes 'true' or 'false'
export const filterStatuses = {
    all: '0',
    has: '1',
    doesNotHave: '2',
    pending: '3',
    false: 'false',
    true: 'true'
};

// `possibleStatuses` order matters - we cycle through these when the icon is clicked
export const commissionSearchType = {
    value: 'commissionSearchType',
    status: filterStatuses.all,
    possibleStatuses: [
        filterStatuses.all,
        filterStatuses.has,
        filterStatuses.pending,
        filterStatuses.doesNotHave
    ],
    label: 'Has commission',
    labelPending: 'Pending commission',
    labelFalse: 'No commission',
    labelNone: 'Filter by commission',
    icon: <DollarSign />
};

export const overrideSearchType = {
    value: 'overrideSearchType',
    status: filterStatuses.all,
    possibleStatuses: [
        filterStatuses.all,
        filterStatuses.has,
        filterStatuses.pending,
        filterStatuses.doesNotHave
    ],
    label: 'Has override',
    labelPending: 'Pending override',
    labelFalse: 'No override',
    labelNone: 'Filter by override',
    icon: <Award />
};

export const isFullyPaid = {
    value: 'isFullyPaid',
    status: filterStatuses.all,
    possibleStatuses: [
        filterStatuses.all,
        filterStatuses.true,
        filterStatuses.false
    ],
    label: 'Is fully paid',
    labelFalse: 'Not fully paid',
    labelNone: 'Filter by fully paid',
    icon: <Star />
};

export const flagSearchType = {
    value: 'flagSearchType',
    status: filterStatuses.all,
    possibleStatuses: [
        filterStatuses.all,
        filterStatuses.has,
        filterStatuses.pending,
        filterStatuses.doesNotHave
    ],
    label: 'Flagged',
    labelPending: 'Flags dismissed',
    labelFalse: 'No flags',
    labelNone: 'Filter by flag status',
    icon: <Flag />
};

export default [
    commissionSearchType,
    overrideSearchType,
    flagSearchType,
    isFullyPaid
];
