import React, { useEffect } from 'react';
import classes from './treeForm.css';
import NonAggregateFormFields from './nonAggregateFormFields';
import AggregateFormFields from './aggregateFormFields';
import FieldDropdownSelect from './fieldDropdownSelect';
import { string, bool, object, func, number, oneOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions/app';
import treeFormDescriptions from 'src/constants/treeFormDescriptions.js';
import TreeFormDate from './TreeFormDate';
import { treeFormDefaultDates } from 'src/constants/dateRanges';
import useFormValidation from 'src/util/useFormValidation';
import validationRules from './validationRules';

import { Button, FormField } from 'fronds/Controllers';

TreeForm.defaultProps = {
    description: 'New rule',
    priorityIndex: null,
    effectiveDateStart: null,
    effectiveDateEnd: null
};

function TreeForm(props) {
    const dispatch = useDispatch();

    let {
        description,
        explicitlyNamedBeneficiaryId,
        commissionRuleTreeType,
        beneficiaryType,
        payoutCanBeAddedToOtherRules,
        effectiveDateStart,
        effectiveDateEnd,
        onSubmit,
        isEditing,
        priorityIndex,
        AggregateMaxPayoutFrequencyInDays,
        DaysToWaitBeforeProcessing,
        AggregateDateScope,
        enabled
    } = props;

    let {
        fieldTypes: { TreeTypes, BeneficiaryTypes }
    } = useSelector(state => state.app);

    //TODO: Remove when constants have value
    TreeTypes = TreeTypes.map(field => ({
        value: field.name,
        label: field.description
    }));

    BeneficiaryTypes = BeneficiaryTypes.map(field => ({
        value: field.name,
        label: field.description
    }));

    if (!commissionRuleTreeType) commissionRuleTreeType = TreeTypes[0].value;

    if (!beneficiaryType) beneficiaryType = BeneficiaryTypes[0].value;

    const isExplicitlyNamedBeneficiary = beneficiaryType === 'ExplicitlyNamed';

    let [currentRule, setCurrentRule, formState] = useFormValidation(
        {
            description,
            explicitlyNamedBeneficiaryId,
            commissionRuleTreeType,
            beneficiaryType,
            payoutCanBeAddedToOtherRules,
            effectiveDateStart,
            effectiveDateEnd,
            enabled
        },
        validationRules
    );

    // For edit form
    // If id is present, add required properties
    useEffect(() => {
        if (props.id) {
            setCurrentRule(rule => ({
                id: props.id,
                priorityIndex: props.priorityIndex,
                ...rule,
                nodes: props.nodes
            }));
        }
    }, []);

    function onFormChange(ev, value) {
        const newForm = { ...currentRule };
        newForm[ev.target.name] = ev.target[value];
        setCurrentRule(newForm);
    }

    function onSelectionChange(selection, field) {
        const newForm = { ...currentRule };
        newForm[field] = selection.value;
        setCurrentRule(newForm);
    }

    function submit() {
        onSubmit(currentRule);
        dispatch(actions.removeCachedRoutes());
    }

    return (
        <form className={classes.root}>
            <div className={classes.form}>
                <FormField
                    label="Description:"
                    description={treeFormDescriptions.description}
                >
                    <input
                        name="description"
                        onChange={val => onFormChange(val, 'value')}
                        value={currentRule.description}
                        type="text"
                        autoFocus={true}
                    />
                </FormField>
                {isExplicitlyNamedBeneficiary && (
                    <FormField
                        label="User:"
                        description={treeFormDescriptions.user}
                    >
                        <input
                            name="explicitlyNamedBeneficiaryId"
                            onChange={e => onFormChange(e, 'value')}
                            value={currentRule.explicitlyNamedBeneficiaryId}
                            type="text"
                        />
                    </FormField>
                )}
                <FormField
                    label="Effective Start Date:"
                    description={treeFormDescriptions.effectiveDateStart}
                >
                    <TreeFormDate
                        currentRule={currentRule}
                        setCurrentRule={setCurrentRule}
                        getFormat={treeFormDefaultDates.getStartFormat}
                        name="effectiveDateStart"
                        initialDate={treeFormDefaultDates.initialStartDate}
                        formState={formState}
                    />
                </FormField>
                <FormField label="Effective End Date:">
                    <TreeFormDate
                        currentRule={currentRule}
                        setCurrentRule={setCurrentRule}
                        name="effectiveDateEnd"
                        getFormat={treeFormDefaultDates.getEndFormat}
                        initialDate={treeFormDefaultDates.initialEndDate}
                        formState={formState}
                    />
                </FormField>
                <FormField
                    label="Commission Tree Type:"
                    description={treeFormDescriptions.commissionTreeType}
                >
                    <FieldDropdownSelect
                        fieldTypes={TreeTypes}
                        fieldName={'commissionRuleTreeType'}
                        currentType={commissionRuleTreeType}
                        onSelectionChange={onSelectionChange}
                        isEditing={isEditing}
                    />
                </FormField>
                <FormField
                    label="Beneficiary Type:"
                    description={treeFormDescriptions.beneficiaryType}
                >
                    <FieldDropdownSelect
                        fieldTypes={BeneficiaryTypes}
                        fieldName={'beneficiaryType'}
                        currentType={beneficiaryType}
                        onSelectionChange={onSelectionChange}
                        isEditing={isEditing}
                    />
                </FormField>
                {currentRule.commissionRuleTreeType
                    .toLowerCase()
                    .includes('aggregate') ? (
                    <AggregateFormFields
                        AggregateMaxPayoutFrequencyInDays={
                            AggregateMaxPayoutFrequencyInDays
                        }
                        DaysToWaitBeforeProcessing={DaysToWaitBeforeProcessing}
                        AggregateDateScope={AggregateDateScope}
                        onFormChange={onFormChange}
                        onSelectionChange={onSelectionChange}
                        setCurrentRule={setCurrentRule}
                        {...currentRule}
                    />
                ) : (
                    <NonAggregateFormFields
                        payoutCanBeAddedToOtherRules={
                            payoutCanBeAddedToOtherRules
                        }
                        isEditing={isEditing}
                        onFormChange={onFormChange}
                        priorityIndex={priorityIndex}
                        setCurrentRule={setCurrentRule}
                        {...currentRule}
                    />
                )}
            </div>
            <div className={classes.controls}>
                {/* The function below should be combined with
                    src/components/dialogue/messages */}
                {formState.messages.length > 0 ? (
                    <div className={classes.errorMessage}>
                        <p> {formState.messages.join(', ')}</p>
                    </div>
                ) : null}
                <div className={classes.formButtons}>
                    <Button
                        onClick={() =>
                            dispatch(actions.setConfirmationDialogue(null))
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={!formState.isValid}
                        name="submit"
                        onClick={() => submit()}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
}

TreeForm.propTypes = {
    description: string,
    commissionRuleTreeType: string,
    beneficiaryType: string,
    effectiveDateStart: string,
    effectiveDateEnd: string,
    payoutCanBeAddedToOtherRules: bool,
    nodes: object,
    onSubmit: func,
    isEditing: bool,
    id: number,
    enabled: bool
};

// Copy the TreeForm and add different defaultProps / propTypes
const EditAggregateTreeForm = TreeForm.bind();

EditAggregateTreeForm.defaultProps = {
    isEditing: true
};

const EditPropTypes = {
    ...TreeForm.propTypes,
    description: string.isRequired,
    commissionRuleTreeType: string.isRequired,
    beneficiaryType: string.isRequired,
    effectiveDateStart: string.isRequired,
    effectiveDateEnd: string.isRequired,
    nodes: object.isRequired,
    onSubmit: func.isRequired,
    id: number.isRequired
};

EditAggregateTreeForm.propTypes = {
    ...EditPropTypes,
    AggregateDateScope: oneOf(['Monthly', 'Quarterly', 'Annually']),
    DaysToWaitBeforeProcessing: number,
    AggregateMaxPayoutFrequencyInDays: number
};

const EditNonAggregateTreeForm = TreeForm.bind();

EditNonAggregateTreeForm.propTypes = {
    ...TreeForm.propTypes,
    payoutCanBeAddedToOtherRules: bool.isRequired,
    priorityIndex: number.isRequired
};

EditNonAggregateTreeForm.defaultProps = {
    isEditing: true
};

export { EditAggregateTreeForm, EditNonAggregateTreeForm };

export default TreeForm;
