import React, { useState, useEffect, useRef } from 'react';
import classes from './commissionRules.css';
import headers from './headers';
import TableWrapper from 'src/components/TableWrapper';
import PageHeader from 'src/components/PageHeader';
import { MiniSidebar, MiniTabs } from 'fronds/Components';
import {
    object,
    array,
    arrayOf,
    shape,
    number,
    string,
    bool
} from 'prop-types';
import { useNavigation } from 'react-navi';
import actions from 'src/store/actions/app';
import { TreeFormButton } from 'src/components/TreeForm';
import { updatePriority } from 'src/store/actions/ruleAsyncActions';
import { useSelector, useDispatch } from 'react-redux';
import Splash from 'src/components/Splash';
import NoRules from 'src/icons/norules2.svg';
import userRoles from 'src/constants/userRoles';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import { Button } from 'fronds/Controllers';
import Reorder from 'icons/reorder.svg';
import { recalculateRulesForTreeType } from '../../store/actions/ruleAsyncActions';

function EditRule(props) {
    const { row } = props;
    const navigation = useNavigation();
    return (
        <Button
            onClick={() =>
                navigation.navigate({
                    href: '/commission-rules/tree',
                    query: {
                        id: row.id
                    }
                })
            }
        >
            View/Edit
        </Button>
    );
}

function CommissionRulesPage(props) {
    const {
        treeGroups,
        queryParams,
        BeneficiaryTypes,
        TreeTypes,
        treeType,
        beneficiaryType
    } = props;
    let { filteredRows } = props;
    let dispatch = useDispatch();
    const { roles } = useSelector(state => state.user);
    const [isReordering, setIsReordering] = useState(false);

    function handleTreeTypeSelectChange(newTreeType) {
        queryParams.setTreeType(newTreeType).setURLParams();
    }
    function handleBeneficiaryTypeSelectChange(newBeneficiaryType) {
        queryParams.setBeneficiaryType(newBeneficiaryType).setURLParams();
    }

    function TreeItem(props) {
        function clickTab() {
            handleTreeTypeSelectChange(props.value);
        }

        return <div onClick={() => clickTab()} {...props} />;
    }
    function BeneficiaryTypeItem(props) {
        function clickTab() {
            handleBeneficiaryTypeSelectChange(props.value);
        }

        return <div onClick={() => clickTab()} {...props} />;
    }

    TreeItem.propTypes = {
        value: string
    };
    BeneficiaryTypeItem.propTypes = {
        value: string
    };

    useEffect(() => {
        setIsReordering(false);
    }, [filteredRows]);

    function selectReorder() {
        if (isReordering) {
            filteredRows.forEach((row, i) => {
                row.priorityIndex = i;
            });

            dispatch(updatePriority(filteredRows));
        }
        setIsReordering(!isReordering);
    }

    // When reordering, set confirm route change to true
    useEffect(() => {
        dispatch(actions.setConfirmRouteChange(isReordering));
    }, [isReordering, dispatch]);

    function getReorderButton() {
        return filteredRows.length > 1 ? (
            <Button onClick={() => selectReorder()}>
                {isReordering ? 'Save' : 'Reorder'}
            </Button>
        ) : (
            <div />
        );
    }

    const resetRows = () => {
        filteredRows = filteredRows.sort((a, b) => {
            return a.priorityIndex - b.priorityIndex;
        });
    };

    const buttonRef = useRef(null);
    const fallback = (
        <Splash
            image={<NoRules />}
            message={"This category doesn't have any rules yet"}
            callToAction={'Create a New Rule'}
            action={() => buttonRef.current.click()}
        />
    );

    const locateTree = selectedTree => {
        if (selectedTree && selectedTree.value) {
            let selectedRuleTreeType = TreeTypes.find(
                type => type.name === selectedTree.value.commissionRuleTreeType
            ).value;

            let selectedBeneficiaryType = BeneficiaryTypes.find(
                type => type.name === selectedTree.value.beneficiaryType
            ).value;

            handleBeneficiaryTypeSelectChange(selectedBeneficiaryType);
            handleTreeTypeSelectChange(selectedRuleTreeType);
        }
    };

    const getTreeTypeName = () =>
        TreeTypes.find(type => type.value === treeType).name;

    return (
        <>
            <PageHeader text={'Commission Rules'} />
            <div className={classes.mainContent}>
                <MiniSidebar
                    ItemComponent={TreeItem}
                    className={classes.sidebar}
                    selectedItem={treeType}
                    items={TreeTypes}
                    customKey={'value'}
                />
                {/* TODO: Initialize with group and benefeciary type selected */}
                <div className={classes.tableController}>
                    <DropdownSelectWrapper
                        listItems={treeGroups}
                        inheritedClass="large"
                        placeholder={'Search by name...'}
                        isClearable={true}
                        onSelectionChange={locateTree}
                    />
                    <div />
                    <div className={classes.actionButtons}>
                        {TreeTypes.length > 0 && (
                            <Button
                                onClick={() => {
                                    dispatch(
                                        recalculateRulesForTreeType(treeType)
                                    );
                                }}
                            >
                                {`Recalculate ${getTreeTypeName()} Rules`}
                            </Button>
                        )}
                        {getReorderButton()}
                        <TreeFormButton
                            buttonRef={buttonRef}
                            beneficiaryType={beneficiaryType}
                            commissionRuleTreeType={treeType}
                        />
                    </div>
                </div>
                <MiniTabs
                    ItemComponent={BeneficiaryTypeItem}
                    className={classes.tabs}
                    selectedItem={beneficiaryType}
                    items={BeneficiaryTypes}
                    title=""
                    customKey={'value'}
                />
                <TableWrapper
                    className={classes.table}
                    rows={filteredRows}
                    headers={headers}
                    fallback={fallback}
                    isEditing={isReordering}
                    setIsEditing={setIsReordering}
                    resetRows={resetRows}
                    draggable={roles.includes(userRoles.admin)}
                    dragIcon={<Reorder width={'20px'} height={'20px'} />}
                />
            </div>
        </>
    );
}

CommissionRulesPage.propTypes = {
    trees: arrayOf(
        shape({
            id: number.isRequired,
            description: string.isRequired,
            commissionRuleTreeType: string.isRequired,
            priorityIndex: number.isRequired,
            beneficiaryType: string.isRequired,
            effectiveDateStart: string,
            effectiveDateEnd: string,
            payoutCanBeAddedToOtherRules: bool.isRequired,
            enabled: bool.isRequired
        })
    ),
    treeGroups: array,
    BeneficiaryTypes: array,
    TreeTypes: array,
    filteredRows: array,
    beneficiaryType: string,
    treeType: string,
    queryParams: object
};

EditRule.propTypes = {
    row: object
};

export default React.memo(CommissionRulesPage);
