import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableDraggable } from 'fronds/Components';
import classes from './tableWrapper.css';
import { array, string, bool, number, oneOfType } from 'prop-types';
import { useSelector } from 'react-redux';
const isEqual = require('lodash/isEqual');

function TableWrapper(props) {
    const { rows, primaryKey = 'id', draggable = false, scrollId } = props;
    const { selectedRow } = useSelector(state => state.table);
    const { pendingRows } = useSelector(state => state.table);
    const [rowProps, setRowProps] = useState({});

    const scrollRef = useCallback(node => {
        if (node !== null) {
            node.scrollIntoView({ block: 'center' });
        }
    }, []);

    useEffect(() => {
        let tempRowProps = {};
        if (selectedRow) {
            tempRowProps = {
                ...tempRowProps,
                [rows.findIndex(row => {
                    // If no single primary key, compare entire row
                    return isEqual(selectedRow, row);
                })]: { className: classes.selected }
            };
        }
        if (pendingRows) {
            pendingRows.forEach(pendingRow => {
                tempRowProps = {
                    ...tempRowProps,
                    [rows.findIndex(row => {
                        return isEqual(pendingRow, row);
                    })]: {
                        ...tempRowProps[rows.findIndex(row => row[primaryKey])],
                        className: classes.pending
                    }
                };
            });
        }

        if (scrollId) {
            tempRowProps = {
                ...tempRowProps,
                [rows.findIndex(row => row[primaryKey] === scrollId)]: {
                    ...tempRowProps[rows.findIndex(row => row[primaryKey])],
                    ref: scrollRef
                }
            };
        }
        setRowProps(tempRowProps);
    }, [selectedRow, pendingRows, rows, primaryKey, scrollRef, scrollId]);

    return (
        <div className={classes.root}>
            {draggable ? (
                <TableDraggable {...props} rowProps={rowProps} />
            ) : (
                <Table {...props} rowProps={rowProps} />
            )}
        </div>
    );
}

TableWrapper.propTypes = {
    rows: array,
    primaryKey: oneOfType([string, bool]),
    draggable: bool,
    scrollId: number
};

export default TableWrapper;
