import React from 'react';
import store from 'src/store';
import { useDispatch } from 'react-redux';
import { newRuleForm } from 'src/components/Dialogue/messages.js';
import appActions from 'src/store/actions/app';
import TreeForm, {
    EditAggregateTreeForm,
    EditNonAggregateTreeForm
} from 'src/components/TreeForm';
import { createRule, editRule } from 'src/store/actions/ruleAsyncActions';
import { string, func, object } from 'prop-types';

import { Button } from 'fronds/Controllers';

export function FormButton(props) {
    const { setConfirmationDialogue } = appActions;
    const {
        headerText,
        commissionRuleTreeType,
        buttonText,
        Form,
        buttonRef,
        ...restProps
    } = props;

    const dispatch = useDispatch();
    return (
        <Button
            buttonRef={buttonRef}
            onClick={() =>
                dispatch(
                    setConfirmationDialogue(
                        newRuleForm(
                            <Form
                                commissionRuleTreeType={commissionRuleTreeType}
                                {...restProps}
                            />,
                            headerText
                        )
                    )
                )
            }
        >
            {buttonText}
        </Button>
    );
}

FormButton.propTypes = {
    buttonText: string.isRequired,
    headerText: string.isRequired,
    commissionRuleTreeType: string,
    Form: func.isRequired,
    buttonRef: object
};

export function TreeFormButton(props) {
    const dispatch = useDispatch();
    function submitNewRule(currentRule) {
        dispatch(createRule(currentRule));
        closeDialogue();
    }

    return (
        <FormButton
            headerText={'Create new rule'}
            buttonText={'New Rule'}
            onSubmit={submitNewRule}
            isEditing={false}
            Form={TreeForm}
            {...props}
        />
    );
}

export function TreeFormEditBase(props) {
    const { submitRule, commissionRuleTreeType, text } = props;
    const dispatch = useDispatch();
    let Form;
    if (commissionRuleTreeType.toLowerCase().includes('aggregate')) {
        Form = EditAggregateTreeForm;
    } else {
        Form = EditNonAggregateTreeForm;
    }

    function onSubmit(currentRule) {
        dispatch(submitRule(currentRule));
        closeDialogue();
    }
    return (
        <FormButton
            headerText={text}
            buttonText={text}
            onSubmit={onSubmit}
            isEditing={true}
            Form={Form}
            {...props}
        />
    );
}

export function TreeFormEditButton(props) {
    return (
        <TreeFormEditBase {...props} submitRule={editRule} text="Edit Rule" />
    );
}

export function TreeFormDuplicateButton(props) {
    return (
        <TreeFormEditBase
            {...props}
            submitRule={createRule}
            text="Duplicate Rule"
        />
    );
}

TreeFormEditBase.propTypes = {
    commissionRuleTreeType: string.isRequired,
    submitRule: func.isRequired,
    text: string.isRequired
};

function closeDialogue() {
    store.dispatch(appActions.setConfirmationDialogue(null));
}
