import React, { useState } from 'react';
import { string, func, number, oneOfType, oneOf } from 'prop-types';
import useCurrentRule from './useCurrentRule';
import treeFormDescription from 'src/constants/treeFormDescriptions';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';

import { FormField } from 'fronds/Controllers';

function AggregateFormFields(props) {
    const {
        onFormChange,
        onSelectionChange,
        setCurrentRule,
        AggregateMaxPayoutFrequencyInDays = 0,
        DaysToWaitBeforeProcessing = 0,
        AggregateDateScope = 'Monthly'
    } = props;

    const [currentDateScope, setCurrentDateScope] = useState(
        AggregateDateScope
    );

    // Add all properties to the current rule and
    // deletes them when unmounted
    useCurrentRule(setCurrentRule, {
        AggregateMaxPayoutFrequencyInDays,
        DaysToWaitBeforeProcessing,
        AggregateDateScope
    });

    return (
        <>
            <FormField
                label="Date Scope:"
                description={treeFormDescription.AggregateDateScope}
            >
                <DropdownSelectWrapper
                    listItems={[
                        { value: 'Monthly', label: 'Monthly' },
                        { value: 'Quarterly', label: 'Quarterly' },
                        { value: 'Annually', label: 'Annually' },
                        {
                            value: 'Effective Date Range',
                            label: 'Effective Date Range'
                        }
                    ]}
                    selectedValues={currentDateScope}
                    onSelectionChange={selection => {
                        setCurrentDateScope(selection.label);
                        onSelectionChange(
                            selection.label,
                            'AggregateDateScope'
                        );
                    }}
                />
            </FormField>
            <FormField
                label="Waiting Period in days:"
                description={treeFormDescription.DaysToWaitBeforeProcessing}
            >
                <input
                    onChange={val => onFormChange(val, 'value')}
                    value={DaysToWaitBeforeProcessing}
                    type="number"
                    name="DaysToWaitBeforeProcessing"
                    min="0"
                />
            </FormField>
            <FormField
                label="Max Payout Frequency in Days:"
                description={
                    treeFormDescription.AggregateMaxPayoutFrequencyInDays
                }
            >
                <input
                    onChange={val => onFormChange(val, 'value')}
                    type="number"
                    value={AggregateMaxPayoutFrequencyInDays}
                    name="AggregateMaxPayoutFrequencyInDays"
                    min="0"
                />
            </FormField>
        </>
    );
}

AggregateFormFields.propTypes = {
    onFormChange: func,
    priorityIndex: number,
    onSelectionChange: func,
    DaysToWaitBeforeProcessing: oneOfType([number, string]),
    AggregateMaxPayoutFrequencyInDays: oneOfType([number, string]),
    AggregateDateScope: oneOf(['Monthly', 'Quarterly', 'Annually']),
    setCurrentRule: func
};

export default AggregateFormFields;
